import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import Logo from '../Logo';
import Link from '../Link';
import List from '../List';
import Popup from '../PopupGlobal';
import MessagePopUp from '../MessagePopUp';
import TmpMessagePopUp from '../TmpMessagePopUp';

import Icons from 'common/Icons';

import { toggleMobileHeader } from 'actions/header';
import { sendMessage as sendMessageAction } from 'actions/threads';
import { clearMessagePopup, hideSuccessMessage } from 'actions/claimDocument';

import {
  isCustomerRole,
  isThompsons,
  isAgentRole,
  isUnionMemberRole,
  isAdminRole,
  isPrimaryRole,
  getSettingByName,
  defineColor,
} from 'utils/helpers';

import {
  tasksAppPath,
  claimsAppPath,
  accountAppPath,
  peoplesAppPath,
  organizationsAppPath,
} from 'utils/paths';

import { createMessagePath } from 'pages/Claims/utils/paths';
import {
  ENTITIES,
  LINK_ENTITY_NAME,
  PERMISSION_TYPE,
  STATUSES,
} from 'utils/constants';

import './styles.scss';
import isPermitted from 'utils/isPermitted';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      menu:
        (isAgentRole(props.session.data.roles) ||
          isUnionMemberRole(props.session.data.roles)) &&
        isThompsons()
          ? [
              {
                pathTo: claimsAppPath,
                value: 'Claims',
                type: 'primary-secondary',
              },
            ]
          : [
              {
                pathTo: tasksAppPath,
                value: 'Tasks',
                type: 'primary-secondary',
              },
              {
                pathTo: claimsAppPath,
                value: 'Claims',
                type: 'primary-secondary',
              },
              {
                pathTo: peoplesAppPath,
                value: 'People',
                type: 'primary-secondary',
              },
              {
                pathTo: organizationsAppPath,
                value: 'Organisations',
                type: 'primary-secondary',
              },
            ],
      isModalOpen: false,
    };
  }

  componentWillUnmount() {
    this.props.toggleMobileHeader(false);
  }

  switchPath = () => {
    if (this.props.match.path.indexOf('task') !== -1) {
      this.props.history.push(claimsAppPath);
    } else {
      this.props.history.push(tasksAppPath);
    }
  };

  openMobileHeader = (roles) => {
    if (!isCustomerRole(roles) || this.props.browser.isMobile) {
      this.props.history.push(accountAppPath);
    } else {
      this.props.toggleMobileHeader(!this.props.header.isOpen);
    }
  };

  switchModal = () => {
    const { isMobile, windowSize } = this.props.browser;
    const { history, claimDocument, hideSuccessMessage } = this.props;
    const { showSuccessMsg } = claimDocument;
    const { id } = this.props.claim.data;
    const windowWidth = windowSize[0];

    if (isMobile && windowWidth <= 576) {
      history.push(createMessagePath.replace(/:id/, `${id}`));
    } else {
      if (this.state.isModalOpen) {
        this.props.clearMessagePopup(
          _.get(claimDocument, 'formData.attachments', []),
          showSuccessMsg
        );
      }
      if (showSuccessMsg) {
        hideSuccessMessage();
      }
      this.setState({
        isModalOpen: !this.state.isModalOpen,
      });
    }
  };

  processLinks = (links) => {
    const { roles } = this.props.session.data;
    if (isPrimaryRole(roles)) return links;

    let resultLinks = [...links];
    const isAllowerReadOrganisations = isPermitted(
      roles,
      ENTITIES.organisation,
      PERMISSION_TYPE.read
    );
    const isAllowerReadPeople = isPermitted(
      roles,
      ENTITIES.person,
      PERMISSION_TYPE.read
    );
    const isAllowerReadTasks = isPermitted(
      roles,
      ENTITIES.form,
      PERMISSION_TYPE.read
    );
    const isAllowerReadClaims = isPermitted(
      roles,
      ENTITIES.claim,
      PERMISSION_TYPE.read
    );

    if (!isAllowerReadOrganisations) {
      resultLinks = resultLinks.filter(
        (l) => l.value !== LINK_ENTITY_NAME.organisations
      );
    }

    if (!isAllowerReadPeople) {
      resultLinks = resultLinks.filter(
        (l) => l.value !== LINK_ENTITY_NAME.people
      );
    }

    if (!isAllowerReadClaims) {
      resultLinks = resultLinks.filter(
        (l) => l.value !== LINK_ENTITY_NAME.claims
      );
    }

    if (!isAllowerReadTasks) {
      resultLinks = resultLinks.filter(
        (l) => l.value !== LINK_ENTITY_NAME.tasks
      );
    }

    return resultLinks;
  };

  render() {
    const { settings, session, browser, header, claim, match } = this.props;
    const { isModalOpen } = this.state;
    const { isMobile } = browser;
    const { isOpen } = header;
    const { companyLogo } = settings.data;
    const { roles, email } = session.data;
    const { status } = claim.data;
    const { list } = claim;
    const { path } = match;

    const logoPath = path.indexOf('task') !== -1 ? claimsAppPath : tasksAppPath;
    const showMessage =
      ['/claims', '/tasks', '/account', '/account/edit'].indexOf(
        this.props.location.pathname
      ) >= 0;
    const tmpLogoPath = isThompsons()
      ? isCustomerRole(roles)
        ? list && list.length > 0
          ? list.length > 1
            ? claimsAppPath
            : `${claimsAppPath}/${list[0].id}`
          : claimsAppPath
        : claimsAppPath
      : logoPath;

    const PopupMessage = () => (
      <Popup
        isOpen={isModalOpen}
        onBackdropClick={this.switchModal}
        content={
          isThompsons() && isUnionMemberRole(roles) ? (
            <TmpMessagePopUp
              onCrossClick={this.switchModal}
              defaultEmail={email}
              enabled={list.map((item) => item.status === 'open').length > 1}
              options={list.map((item) => ({
                value: `${
                  isThompsons() ? item.externalRef || '' : item.refId
                } ${item.type}`,
                id: item.id,
              }))}
              isOpen={isModalOpen}
            />
          ) : (
            <MessagePopUp
              onCrossClick={this.switchModal}
              defaultEmail={email}
              enabled={list.map((item) => item.status === 'open').length > 1}
              options={list.map((item) => ({
                value: `${
                  isThompsons() ? item.externalRef || '' : item.refId
                } ${item.type}`,
                id: item.id,
              }))}
              isOpen={isModalOpen}
            />
          )
        }
      />
    );

    const headerLinks = this.processLinks(this.state.menu);

    const textColorSetting = getSettingByName('botMsgTextColor', settings);
    const color = defineColor(textColorSetting);

    return !isMobile ? (
      <header className="main-header flex justify-between items-center">
        <PopupMessage />
        <Logo
          src={companyLogo}
          width={132}
          height={30}
          fullLogo
          pathTo={isThompsons() ? tmpLogoPath : '/claims'}
        />

        {!isCustomerRole(roles) ? (
          <List type="inline">
            {headerLinks.map((item) => (
              <Link
                className="letter-spacing-sm"
                pathTo={item.pathTo}
                value={item.value}
                type={item.type}
                key={item.pathTo}
                activeClassName="active-white"
                exact={false}
                withSettingsColor
              />
            ))}
          </List>
        ) : null}

        <List type="inline account">
          {!showMessage &&
          !isAdminRole(roles) &&
          status &&
          status !== STATUSES.closed ? (
            <Link
              className="text-uppercase letter-spacing-sm"
              value={
                <Icons
                  iconName="message"
                  fill={color}
                  className="message-link"
                />
              }
              type="primary-secondary"
              onBodyClick={this.switchModal}
              withSettingsColor
            />
          ) : null}
          <Link
            className="text-uppercase letter-spacing-sm"
            pathTo={accountAppPath}
            value={<Icons iconName="account" fill={color} />}
            type="primary-secondary"
            withSettingsColor
          />
        </List>
      </header>
    ) : (
      <Fragment>
        <PopupMessage />
        <header className="main-header flex justify-between items-center">
          <Logo
            src={companyLogo}
            width={132}
            height={30}
            fullLogo
            pathTo={isThompsons() ? tmpLogoPath : logoPath}
          />
          <div className="account-header">
            {!showMessage &&
              !isAdminRole(roles) &&
              status &&
              status !== STATUSES.closed && (
                <div
                  onClick={this.switchModal}
                  className="message-mobile-holder"
                >
                  <Icons
                    iconName="message"
                    width={20}
                    height={20}
                    fill={color}
                  />
                </div>
              )}
            <div onClick={() => this.openMobileHeader(roles)}>
              <Icons iconName="account" width={20} height={20} fill={color} />
            </div>
          </div>
        </header>
        <div
          className={`mobile-menu flex direction-column ${
            isOpen ? 'opened' : ''
          }`}
        >
          <List type="">
            {this.state.menu.map((item) => (
              <Link
                className="letter-spacing-sm"
                pathTo={item.pathTo}
                value={item.value}
                type={item.type}
                key={item.pathTo}
                activeClassName="active-white"
                exact={false}
                withSettingsColor
              />
            ))}
          </List>

          <List type="account">
            <Link
              className="text-uppercase letter-spacing-sm"
              pathTo={accountAppPath}
              value={<Icons iconName="account" fill={color} />}
              type="primary-secondary"
              withSettingsColor
            />
          </List>
        </div>
      </Fragment>
    );
  }
}

Header.propTypes = {
  toggleMobileHeader: PropTypes.func.isRequired,
  browser: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  header: PropTypes.shape({
    isOpen: PropTypes.bool,
  }).isRequired,
  settings: PropTypes.shape({
    data: PropTypes.shape({
      companyLogo: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  state,
  browser: state.browser,
  header: state.header,
  settings: state.settings,
  session: state.session,
  claim: state.claim,
  claimDocument: state.claimDocument,
});

const mapDispatchToProps = {
  toggleMobileHeader,
  sendMessageAction,
  clearMessagePopup,
  hideSuccessMessage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
