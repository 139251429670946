import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from 'components/Layout';
import Ring from 'components/Ring';

import { newPath, listPath, rootPath } from './utils/paths';

const OrganizationsList = lazy(() => import('./containers/List'));
const OrganizationsNew = lazy(() => import('./containers/New'));
const OrganizationsDetails = lazy(() => import('./containers/Details'));

const OrganizationsPage = () => (
  <Layout.Base>
    <Suspense fallback={<Ring />}>
      <Switch>
        <Route
          exact
          path={listPath}
          component={(props) => <OrganizationsList {...props} />}
        />
        <Route
          path={newPath}
          component={(props) => <OrganizationsNew {...props} />}
        />
        <Route
          path={rootPath}
          component={(props) => <OrganizationsDetails {...props} />}
        />
      </Switch>
    </Suspense>
  </Layout.Base>
);

export default OrganizationsPage;
