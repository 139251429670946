import React from 'react';
import { connect } from 'react-redux';

import './styles.scss';
import { defineColor, getSettingByName } from 'utils/helpers';

const List = ({ className = '', children, type = '', gap, settings }) => {
  const textColorSetting = getSettingByName('botMsgTextColor', settings);
  const color = defineColor(textColorSetting);

  const listType = type ? `list-${type}` : '';
  const listGap = gap ? `list-${gap}` : '';

  return (
    <nav className="list-wrapper">
      <ul className={`list ${className} ${listType} ${listGap}`}>
        {(children.length > 0 &&
          children.map((item) => (
            <li
              key={children.indexOf(item)}
              className="list-item-wrapper"
              style={{ color }}
            >
              {item}
            </li>
          ))) || (
          <li className="list-item-wrapper" style={{ color }}>
            {children}
          </li>
        )}
      </ul>
    </nav>
  );
};

const mapStateToProps = ({ settings }) => ({ settings });

export default connect(mapStateToProps)(List);
