import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './styles.scss';
import { isPermittedLink } from 'utils/isPermitted';
import { PERMISSION_TYPE } from 'utils/constants';
import { defineColor, getSettingByName, isPrimaryRole } from 'utils/helpers';

const LinkComponent = ({
  pathTo = '',
  className = '',
  activeClassName = '',
  value,
  type = '',
  targetBlank = false,
  linkButton = false,
  prefix,
  sufix,
  exact = true,
  sufixTarget = '_blank',
  sufixLink = false,
  onPrefixClick,
  onBodyClick,
  wrapperClass,
  data = '',
  onClick,
  session,
  disableNavigation,
  settings,
  withSettingsColor,
}) => {
  const textColorSetting = getSettingByName('botMsgTextColor', settings);
  const color =
    withSettingsColor && textColorSetting
      ? defineColor(textColorSetting)
      : 'white';
  const style = withSettingsColor && color ? { color } : {};

  const linkType = type ? `link-${type}` : '';
  const linkRole = linkButton ? 'link-button' : '';
  const sufixClass = sufix ? 'link-has-sufix' : '';
  const prefixClass = prefix ? 'link-has-prefix' : '';
  const handleOnPrefixClick = () => {
    if (disableNavigation) return;
    if (onPrefixClick) onPrefixClick();
  };

  const handleOnClick = (e) => {
    if (disableNavigation) return;
    const rolesList = session.data.roles;
    e.persist();
    const permitted = isPrimaryRole(rolesList)
      ? true
      : isPermittedLink(rolesList, e.target.innerText, PERMISSION_TYPE.read);
    if (!permitted && e) e.preventDefault();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div
      className={`link-wrapper ${linkRole} ${prefixClass} ${sufixClass} ${wrapperClass}`}
      style={style}
    >
      {prefix && (
        <div
          onClick={handleOnPrefixClick}
          role="presentation"
          className="link-prefix"
        >
          {prefix}
        </div>
      )}
      {!onBodyClick && (
        <Fragment>
          {targetBlank ? (
            disableNavigation ? (
              <a
                href={false}
                target="_blank"
                rel="noopener noreferrer"
                className={`link ${className} ${linkType}`}
                style={style}
              >
                {value}
              </a>
            ) : (
              <a
                href={pathTo}
                target="_blank"
                rel="noopener noreferrer"
                className={`link ${className} ${linkType}`}
                style={style}
              >
                {value}
              </a>
            )
          ) : disableNavigation ? (
            <a
              href={false}
              target="_blank"
              rel="noopener noreferrer"
              className={`link ${className} ${linkType}`}
              style={style}
            >
              {value}
            </a>
          ) : (
            <NavLink
              exact={exact}
              onClick={handleOnClick}
              to={pathTo}
              className={`link ${className} ${linkType}`}
              activeClassName={`${activeClassName}`}
              style={style}
            >
              {value}
            </NavLink>
          )}
        </Fragment>
      )}
      {onBodyClick && (
        <div
          onClick={onBodyClick}
          role="presentation"
          className="link link-clickable "
          data={data}
          style={style}
        >
          {value}
        </div>
      )}
      {sufix && sufixLink && (
        <a
          href={pathTo}
          target={sufixTarget}
          className="link-sufix"
          style={{ color }}
        >
          {sufix}
        </a>
      )}
      {sufix && !sufixLink && (
        <div className="link-sufix" style={style}>
          {sufix}
        </div>
      )}
    </div>
  );
};

LinkComponent.propTypes = {
  pathTo: PropTypes.string,
  linkButton: PropTypes.bool,
  targetBlank: PropTypes.bool,
  className: PropTypes.string,
  prefix: PropTypes.node,
  sufix: PropTypes.node,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  activeClassName: PropTypes.string,
  exact: PropTypes.bool,
  sufixTarget: PropTypes.string,
  sufixLink: PropTypes.bool,
  disableNavigation: PropTypes.bool,
  onPrefixClick: PropTypes.func,
  onBodyClick: PropTypes.func,
  wrapperClass: PropTypes.string,
  data: PropTypes.string,
};

const mapStateToProps = ({ session, settings }) => ({
  session,
  settings,
});

export default connect(mapStateToProps)(LinkComponent);
