import React, { Component } from 'react';
import Select, { components } from 'react-select';
import './style.scss';

const { ValueContainer, Placeholder } = components;

const NORMAL_LABEL_LENGTH = 200;
const LABEL_LAPTOP_BREAKPOINT = 1200;
const LABEL_LAPTOP_MIN_BREAKPOINT = 900;
const LABEL_MOBILE_BREAKPOINT = 820;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props} style={{ ...props.style, borderRadius: '15px' }}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => {
        return child && child.type !== Placeholder ? child : null;
      })}
    </ValueContainer>
  );
};

export default class SingleSelect extends Component {
  formatOptions = (options) => {
    return options.map((element) => {
      if (element.superLabel) {
        return { label: element.superLabel, value: element.value };
      }
      return { label: element.value, value: element.value };
    });
  };

  change = (val) => {
    if (val == null) {
      val = [];
    }
    let value = '';
    val.forEach((element, index) => {
      value += index === 0 ? element.value : `, ${element.value}`;
    });
    this.props.onChange(value, this.props.name);
  };

  adjustLabelHeight = () => {
    const { label, value, adjustableLabel } = this.props;
    const windowWidth = window.innerWidth;
    let labelHeight = 'auto';

    if (!label?.length || !adjustableLabel) return labelHeight;

    if (
      label?.length >= NORMAL_LABEL_LENGTH &&
      windowWidth > LABEL_LAPTOP_BREAKPOINT &&
      !value
    ) {
      return '40px';
    } else if (
      label?.length >= NORMAL_LABEL_LENGTH &&
      windowWidth <= LABEL_LAPTOP_BREAKPOINT &&
      !value
    ) {
      return '85px';
    } else if (
      label?.length >= NORMAL_LABEL_LENGTH &&
      windowWidth <= LABEL_LAPTOP_BREAKPOINT &&
      windowWidth >= LABEL_LAPTOP_MIN_BREAKPOINT &&
      value
    ) {
      return '80px';
    } else if (
      label?.length >= NORMAL_LABEL_LENGTH &&
      windowWidth <= LABEL_LAPTOP_MIN_BREAKPOINT &&
      windowWidth >= LABEL_MOBILE_BREAKPOINT &&
      value
    ) {
      return '95px';
    } else {
      return labelHeight;
    }
  };

  render() {
    const { options, name, label, disabled, newDesign } = this.props;
    let { value } = this.props;
    const data = options ? this.formatOptions(this.props.options) : [];

    if (typeof value === 'string' && value.trim().length > 0) {
      value = `[${value}]`;
      value = value.replaceAll('[', '').replaceAll(']', '');
      value = value.split(',').map((el) => ({
        value: el,
        label: el,
      }));
    }

    const newDesignSelect = newDesign ? 'new-design-multi-select' : '';

    return (
      <Select
        isDisabled={disabled}
        className={`basic-single ${newDesignSelect}`}
        // className="basic-single"
        classNamePrefix="select"
        name={name}
        options={data}
        defaultValue={value}
        isClearable
        isMulti
        components={{
          ValueContainer: CustomValueContainer,
        }}
        onChange={(val) => {
          this.change(val);
        }}
        placeholder={label}
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginTop: 50,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            paddingTop: state.hasValue ? 10 : 0,
            height: this.adjustLabelHeight(),
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            top: state.hasValue || state.selectProps.inputValue ? 0 : '50%',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize:
              state.hasValue || state.selectProps.inputValue
                ? '0.625rem'
                : '0.8125rem',
            height: state.hasValue && 2,
          }),
        }}
      />
    );
  }
}
